<script setup lang="ts">
import { AnwRatingBadge } from '@anwalt.de/tailwind-design-system';
import RatingDetail from '@/modules/rating-detail/components/RatingSummary/RatingDetail.vue';
import { defaultRatingOverlay, RatingSummary, RatingSummaryOverlay } from '@shared/types/RatingSummary';
import { computed } from 'vue';
import { useRating } from '@/shared/composables/rating';
import { storeToRefs } from 'pinia';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { useBaseLawyerStore } from '@/shared/stores/BaseLawyerStore';
import { useI18n } from 'vue-i18n';

interface Props {
  rating: RatingSummary;
}
const props = defineProps<Props>();

const { t } = useI18n();
const { profileProductId } = storeToRefs(useProfileProductId());
const { profile } = storeToRefs(useBaseLawyerStore());

const computedRating = computed<RatingSummary | RatingSummaryOverlay>(() => {
  return props.rating.ratingCount ? props.rating : defaultRatingOverlay;
});

const { showRatingBadge } = useRating(computedRating);

const showRatingOverlay = computed(() => {
  return props.rating.ratingCount === 0;
});

const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();
</script>

<template>
  <div class="relative">
    <div class="mb-6 md:hidden">
      <AnwRatingBadge
        class="p-0"
        :number-of-ratings="computedRating.ratingCount"
        :show-amount-of-ratings-text="true"
        :rating-average="computedRating.ratingAverage"
        :show-rating-text="showRatingBadge" />
    </div>
    <RatingDetail :rating-summary="computedRating" />
    <div
      v-html="
        getTranslationByProductId('summaryNoRatings', profileProductId, {
          genderedLawyer: t('summaryNoRatings.thisLawyer', { n: getGenderValueForTranslation(profile.sex) }),
        })
      "
      v-if="showRatingOverlay"
      class="backdrop-blur-[2px] bg-white/90 absolute inset-0 grid place-content-center text-center anw-text-p3 font-semibold"></div>
  </div>
</template>
