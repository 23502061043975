<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons';

import { RatingHelper } from '@/shared/helper/RatingHelper';
import { computed } from 'vue';

const props = defineProps<{
  ratingAverage: number;
}>();

const { getRatingLabel } = RatingHelper();
const ratingLabel = computed(() => getRatingLabel(props.ratingAverage));
</script>
<template>
  <div class="px-4 py-1.5 bg-info-50 rounded-full border border-info-100 justify-start items-center gap-2 inline-flex">
    <div class="text-info-700 text-sm">{{ ratingLabel }}</div>
    <FontAwesomeIcon :icon="faThumbsUp" class="text-[15px] text-info-700" aria-hidden="true" />
  </div>
</template>
