<script setup lang="ts">
import { AnwRatingStars } from '@anwalt.de/tailwind-design-system';
import RatingThumbUp from '@/modules/rating-detail/components/RatingSummary/RatingThumbUp.vue';
import { RatingHelper } from '@/shared/helper/RatingHelper';
import { NumberFormatter } from '@/shared/helper/NumberFormatter';

defineProps<{
  ratingCount: number;
  ratingAverage: number;
}>();

const { showRatingBadge } = RatingHelper();
</script>

<template>
  <div class="w-[255px] hidden mr-10 py-6 bg-neutral-50 rounded md:grid place-items-center">
    <div class="text-info-700 font-bold anw-h1 mb-0">{{ NumberFormatter().formatAverage(ratingAverage) }}</div>
    <AnwRatingStars class="mb-4" :rating-average="ratingAverage" />
    <RatingThumbUp v-if="showRatingBadge(ratingCount, ratingAverage)" :rating-average="ratingAverage" />
  </div>
</template>
