<script setup lang="ts">
import RatingBar from '@/modules/rating-detail/components/RatingSummary/RatingBar.vue';
import { SummaryDetails } from '@shared/types/RatingSummary';
import { computed } from 'vue';

const props = defineProps<{
  totalRatings: number;
  summaryDetails: SummaryDetails;
}>();

const calcWidth = (ratingCount: number) => {
  return (ratingCount * 100) / props.totalRatings;
};

const ratingCounts = computed<Array<number>>(() => {
  return Object.values(props.summaryDetails)
    .filter((val) => !isNaN(val))
    .reverse();
});
</script>

<template>
  <div class="grid gap-y-2.5 grid-cols-[auto_1fr_auto] items-center" :class="'grid-rows-' + ratingCounts.length">
    <RatingBar
      v-for="(rating, i) in ratingCounts"
      :key="i"
      :rating="ratingCounts.length - i"
      :width="calcWidth(rating)"
      :rating-count="rating" />
  </div>
</template>
